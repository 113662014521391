import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import Label from "@material-ui/icons/Label"
import VideosGrid from "../components/VideosGrid"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import SEO from "../components/seo"

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    marginBottom: ".2em",
  },
  backButton: {
    textDecoration: "none",
  },
  flexBoxParentDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    "&::after": {
      content: "",
      flex: "auto",
    },
  },
  chipLabel: {
    color: "#c5c5c5",
  },

  filtersGridParent: {
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filteredItemsLength: {
    textAlign: "left",
    fontWeight: 400,
  },
  GridParent: {
    padding: 20,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  tagChips: {
    margin: "3px 3px 3px 0px",
    height: 19,
    fontSize: 14,
    backgroundColor: "#acacac",
    color: "white",
  },
  tagArea: {
    marginTop: 5,
    display: "flex",
    justifyContent: "space-evenly",
    minWidth: 294,
    flexWrap: "wrap",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
})

const TagPage = ({ data, classes, pageContext }) => {
  const itemsWithTag = data.allItem.edges

  console.log(itemsWithTag)

  return (

      <div className={classes.root}>
        <Grid container spacing={8} className={classes.GridParent}>
          <Grid item lg={8} md={8} xs={12}>
            {itemsWithTag.length < 1 ? (
              <div className={classes.CircularProgressDiv}>
                {" "}
                <CircularProgress
                  className={classes.CircularProgressItself}
                />{" "}
              </div>
            ) : (
              <VideosGrid locations={itemsWithTag} />
            )}
          </Grid>

          <Grid item lg={4} md={4} xs={12}>
            <Typography
              className={classes.filteredItemsLength}
              variant="title"
              component="h4"
            >
              {itemsWithTag.length} Videos tagged with{" "}
              <Chip
                clickable
                label={"#" + pageContext.tag}
                key={pageContext.tag.toString()}
                className={classes.tagChips}
              />
            </Typography>

            <div className={classes.tagArea}></div>
          </Grid>
        </Grid>
      </div>

  )
}

export default withStyles(styles)(TagPage)

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allItem(filter: { tags: { in: [$tag] } }) {
      totalCount
      edges {
        node {
          thumbnail_link
       
          title
          tags
          publishedAt
          channelName
          description
          video_id 
          view_count
        }
      }
    }
  }
`
